import React from "react";
// import { MultiSelect } from 'primereact/multiselect';
import { PrimeReactProvider } from 'primereact/api';
import './App.css';

import Graph from "./Components/Graph";

function App() {



// Disable right-click

function  disableRightClick() {
document.addEventListener('contextmenu', (e) => e.preventDefault());

function ctrlShiftKey(e, keyCode) {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
}

document.onkeydown = (e) => {
  // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
  if (
    event.keyCode === 123 ||
    ctrlShiftKey(e, 'I') ||
    ctrlShiftKey(e, 'J') ||
    ctrlShiftKey(e, 'C') ||
    (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
  )
    return false;
}; 
}
// disableRightClick();



  return (
    <PrimeReactProvider>
      <div className="App">
      <Graph/>
      </div>
    </PrimeReactProvider>
  );
}

export default App;

  // const [selectedCities, setSelectedCities] = useState(null);
  // const cities = [
  //   { name: 'BankNifty', code: 'BN' },
  //   { name: 'Nifty', code: 'NI' },
  //   { name: 'FINNIFTY', code: 'FIN' },
   
  // ];
  // <div className="card flex justify-content-center">
  //         <MultiSelect value={selectedCities} onChange={(e) => setSelectedCities(e.value)} options={cities} optionLabel="name"
  //           filter placeholder="Select Products ... " maxSelectedLabels={0} className="w-full md:w-20rem" />
  //       </div>
  //       <Bar/>
import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import "primereact/resources/themes/md-dark-deeppurple/theme.css";
import anime from "animejs/lib/anime.es.js"; // Import anime.js library

export default function Bar({ parentName, optionData }) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const animateChart = () => {
    anime({
      targets: ".card", // Assuming your chart is within an element with the class 'chart-container'
      opacity: [0, 1],
      easing: "easeInOutQuad", // You can change easing as per your preference
      duration: 500, // Duration of the animation in milliseconds
      delay: 150, // Delay before the animation starts in milliseconds
    });
  };

  useEffect(() => {
    if (optionData) {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--text-color-secondary"
      );
      const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

      const data = {
        labels: [
          "ITM 5%+",
          "ITM 2%-5%",
          "ITM 0%-2%",
          "OTM 0%-2%",
          "OTM 2%-5%",
          "OTM 5%+",
        ],
        datasets: [
          {
            label: parentName + " Buy",
            backgroundColor: documentStyle.getPropertyValue("--blue-400"),
            borderColor: documentStyle.getPropertyValue("--blue-800"),
            data: optionData.buy,
          },
          {
            label: parentName + " Write",
            backgroundColor: documentStyle.getPropertyValue("--orange-300"),
            borderColor: documentStyle.getPropertyValue("--orange-500"),
            data: optionData.write,
          },
        ],
      };

      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          legend: {
            labels: {
              color: "white",
              font: {
                size: 20,
                weight: 500,
              },
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                var label = context.dataset.label || "";
                if (label) {
                  label += " : ";
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y;
                }
                return label;
              },
            },
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            titleFont: {
              size: 20,
            },
            bodyFont: {
              size: 20,
            },
            bodySpacing: 2,
            displayColors: false,
            caretPadding: 10,
            cornerRadius: 10,
            mode: "index",
            intersect: false,
            position: "nearest",
            enabled: true,
            boxWidth: 100,
            boxHeight: 100,
          },
        },
        scales: {
          x: {
            ticks: {
              color: "white",
              font: {
                size: 16,
                weight: 500,
              },
            },
            grid: {
              display: true,
              drawBorder: true,
            },
          },
          y: {
            ticks: {
              color: "white",
              font: {
                size: 15,
                weight: 500,
              },
            },
            grid: {
              color: surfaceBorder,
              drawBorder: false,
            },
          },
        },
      };
      animateChart();
      setChartData(data);
      setChartOptions(options);
      return () => {};
    }
  }, [optionData]);

  return (
    <div className="card">
      {optionData ? (
        <Chart type="bar" data={chartData} options={chartOptions} />
      ) : (
        ""
      )}
    </div>
  );
}

import React, { useState, useMemo, useEffect, useCallback } from 'react';
import Header from './Header';
import './Graph.css';
import _ from 'lodash';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CssBaseline from '@mui/material/CssBaseline';
import Bar from './Bar';
// import Slider from './Slider';
import Line from './Line';
// import "./Slider.css";
import MultiRangeSlider from "multi-range-slider-react";
import axios from "axios";



import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';



import anime from 'animejs/lib/anime.es.js';

const filter = createFilterOptions();

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

// const mapData = new Map();



let baseUrl = "http://192.168.1.68:5567"; // IP of the server running on Raspberry

function Graph() {
    const [value, setValue] = useState('BANKNIFTY');
    const [arr, setArr] = useState([]);
    const [labelHolder, setLabelHolder] = useState([]);
    const [mapData, setMapData] = useState(new Map());

    const [barData, setBarData] = useState({});

    const [data, setData] = useState(null);
    const [retryCount, setRetryCount] = useState(0);
    const maxRetries = 5;

    const [symbolName, setSymbolName] = useState([]);
    const [selectedSymbol, setSelectedSymbol] = useState('BANKNIFTY');


    const weekDays = ["5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "60", "70", "80", "90", "100"];



    // Use this  to get the data for a particular stock and plot it on graph
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(14);



    const [minValue2, setMinValue2] = useState(0);
    const [maxValue2, setMaxValue2] = useState(14);

    const [minCaption, setMinCaption] = useState(weekDays[minValue]);
    const [maxCaption, setMaxCaption] = useState(weekDays[maxValue]);

        
    // State to track whether data has been received
    const [dataReceived, setDataReceived] = useState(false);



    const handleValueChange = () => {
        console.log("function called ")
        let newSliceArr = weekDays.slice(minValue, maxValue + 1);
        for (let currentArrobj of arr) {
            let tempCurrentobj = _.cloneDeep(currentArrobj);
            let newDataPoints = _.cloneDeep(currentArrobj.data.slice(minValue, maxValue + 1));
            tempCurrentobj.data = newDataPoints;
            mapData.set(tempCurrentobj.name, tempCurrentobj);
        }
        setLabelHolder(newSliceArr)
    };

    useMemo(() => handleValueChange(), [minValue, maxValue]);



    const animateChart = () => {
        anime({
            targets: ['.navbar','.handlemultiselector'], // Assuming your chart is within an element with the class 'chart-container'
            opacity: [0, 0.6, 0.7, 0.8, 0.9, 1],
            scale: [0, 1],
            easing: 'easeInOutQuad', // You can change easing as per your preference
            duration: 1000, // Duration of the animation in milliseconds
            delay: 50 // Delay before the animation starts in milliseconds
        });
    };

    useEffect(() => {
        axios.get(`${baseUrl}/option`)
            .then(response => {
                // Handle the response data here
                console.log(response.data); // Log the received data
                setSymbolName(response.data);
            })
            .catch(error => {
                // Handle errors here
                console.error('There was a problem with the axios operation:', error);
            });
        animateChart();
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`${baseUrl}/option`);
                const data = await response.json();
                return data;
            } catch (error) {
                console.error("API request failed:", error);
                return null;
            }
        }

        async function retryFetch() {
            if (retryCount >= maxRetries) {
                console.error("Max retry limit reached. Data not received from API.");
                return;
            }

            const data = await fetchData();
            console.log(data);
            if (!data) {
                setRetryCount(retryCount + 1);
                console.log(`Retrying API call. Retry count: ${retryCount + 1}`);
                setTimeout(retryFetch, 1000); // Retry after 1 second
            } else {
                console.log("Data received from API:", data);
                // setData(data);
                // Process the received data further as needed
            }
        }

        // Start the initial fetch
        retryFetch();
    }, [retryCount]);


    /*************************** Component Return ***********************/

    // useEffect(() => {
    //     console.log("Function call for bar");

    //     const fetchData = async () => {
    //         try {
    //             const response = await axios.get(`${baseUrl}/getBarData`);
    //             setBarData(response.data.data);
    //             console.log(response.data.data);
    //         } catch (error) {
    //             // Handle error
    //             console.error('Error:', error);
    //         }
    //     };

    //     // Fetch data initially
    //     //fetchData();

    //     // Set interval to fetch data every 5000 milliseconds
    //     const interval = setInterval(fetchData, 15000);

    //     // Cleanup function to clear interval when component unmounts
    //     return () => clearInterval(interval);
    // }, []);



    // const makeApiCall = (name) => {
    //     if (!name || name.trim() === '') {
    //         console.error("Invalid name provided.");
    //         return;
    //     }
    //     setSelectedSymbol(name);
    //     // Make your API call here with the provided value and query parameters
    //     // Example:
    //     fetch(`${baseUrl}/getDataAll?name=${name}`)
    //         .then(response => response.json())
    //         .then(data => {
    //             // Handle API response data
    //             console.log("API Response:", data);

    //             let array = data.lineData;
    //             for (let i = 0; i < array.length; i++) {
    //                 let arr_key = array[i].name;
    //                 let arr_value = array[i];
    //                 mapData.set(arr_key, arr_value);
    //             }
    //             console.log(data.lineData);
    //             setArr(data.lineData);
    //             setBarData(data.barData);

    //         })
    //         .catch(error => {
    //             // Handle errors
    //             console.error("API Error:", error);
    //         });
    // };



    // // useEffect to call makeApiCall initially and then every 10 minutes
    // useEffect(() => {
    //     console.log("function called");
    //     console.log(selectedSymbol);
    //     let intervalId;
    //     // Check if selectedSymbol is not null or empty string
    //     if (selectedSymbol && selectedSymbol.trim() !== '') {
    //         // Call makeApiCall initially with the selected name
    //         makeApiCall(selectedSymbol);

    //         // Set interval to call makeApiCall every 10 minutes
    //         intervalId = setInterval(() => {
    //             makeApiCall(selectedSymbol);
    //         }, 20000); // 10 minutes * 60 seconds * 1000 milliseconds  900000

    //         // Clean up function to clear the interval when component unmounts
    //         return () => clearInterval(intervalId);
    //     } else {
    //         console.error("Invalid selected symbol.");
    //         return () => clearInterval(intervalId);

    //     }
    // }, [selectedSymbol]);




    const makeApiCall = (name) => {
        if (!name || name.trim() === '') {
            console.error("Invalid name provided.");
            return;
        }
        setSelectedSymbol(name);
        // Make your API call here with the provided value and query parameters
        // Example:
        fetch(`${baseUrl}/getDataAll?name=${name}`)
            .then(response => response.json())
            .then(data => {
                // Handle API response data
                console.log("API Response:", data);
    
                let array = data.lineData;
                for (let i = 0; i < array.length; i++) {
                    let arr_key = array[i].name;
                    let arr_value = array[i];
                    mapData.set(arr_key, arr_value);
                }
                console.log(data.lineData);
                setArr(data.lineData);
                setBarData(data.barData);
                // Set a flag indicating that data has been received
                setDataReceived(true);
            })
            .catch(error => {
                // Handle errors
                console.error("API Error:", error);
                // Retry after 5 seconds if data is not received
                setTimeout(() => {
                    makeApiCall(name);
                }, 5000);
            });
    };
    
    // useEffect to call makeApiCall initially and then every 10 minutes
    useEffect(() => {
        console.log("function called");
        console.log(selectedSymbol);
        let intervalId;
        // Check if selectedSymbol is not null or empty string
        if (selectedSymbol && selectedSymbol.trim() !== '') {
            // Call makeApiCall initially with the selected name
            makeApiCall(selectedSymbol);
        }
    
        // Set interval to call makeApiCall every 10 minutes if data has been received
        if (dataReceived) {
            intervalId = setInterval(() => {
                makeApiCall(selectedSymbol);
            }, 600000); // 10 minutes * 60 seconds * 1000 milliseconds
        }
    
        // Clean up function to clear the interval when component unmounts
        return () => clearInterval(intervalId);
    }, [selectedSymbol, dataReceived]);
    




    const handleChange = (event, newValue) => {
        // Do whatever you need to do with the new value
        console.log("New value:", newValue);
        if (newValue != null) {
            // You can call your function here
            makeApiCall(newValue.symbol);

            setValue(newValue); // Update the value state with the new value
        } else {

        }
    };

    // (event, newValue) => {
    //     if (typeof newValue === 'string') {
    //         setValue({
    //             symbol: newValue,
    //         });
    //     } else if (newValue && newValue.inputValue) {
    //         // Create a new value from the user input
    //         setValue({
    //             symbol: newValue.inputValue,
    //         });
    //     } else {
    //         setValue(newValue);
    //     }
    // }


    const items = Array.from({ length: 5 }, (v, i) => i);

    return (
        <div className='main-container'>
            <Header />
            <div className='navbar'>
                <div className='newMyClass'>
                    <ThemeProvider theme={darkTheme}>
                        <Autocomplete
                            value={value}
                            onChange={handleChange}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;

                                // Check if inputValue is null or undefined
                                if (!inputValue || inputValue.trim() === '') {
                                    return filtered;
                                }

                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.symbol);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        symbol: `Add "${inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}

                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="free-solo-with-text-demo"
                            options={symbolName}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.symbol;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.symbol}</li>}
                            sx={{ width: 300 }}

                            renderInput={(params) => (
                                <TextField {...params} label="Symbol Name " />
                            )}
                        />
                    </ThemeProvider>
                </div>
                <div className='slider-handle'>
                    <div className="my-slider">
                        <h3> IV  </h3>
                        <div className="multi-range-slider-container">
                            <MultiRangeSlider
                                labels={weekDays}
                                min={0}
                                max={weekDays.length - 1}
                                minValue={minValue}
                                maxValue={maxValue}
                                step={1}
                                minCaption={minCaption}
                                maxCaption={maxCaption}
                                onInput={(e) => {
                                    setMinCaption(weekDays[e.minValue]);
                                    setMaxCaption(weekDays[e.maxValue]);
                                    setMinValue(e.minValue);
                                    setMaxValue(e.maxValue);
                                    console.log(e.minValue + "  ======  " + e.maxValue);
                                }}
                                onChange={(e) => {
                                    setMinValue2(e.minValue);
                                    setMaxValue2(e.maxValue);
                                }}
                            />
                            {/* <div className="divOutput">
                            
                                <div className='point'>
                                    <span>
                                        Start point :  {weekDays[minValue2]}
                                    </span>
                                    <span>
                                        End Point :  {weekDays[maxValue2]}

                                    </span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

            </div>
            {
                selectedSymbol && barData ?
                    <div className='graph-container'>
                        <div className='bar-chart'>

                            <div className='heading_bar_chart'>
                                {
                                    barData && value ? <>
                                        <h1>Call Data</h1>
                                        <div className='call-bar'>
                                            <Bar parentName={"Call"} optionData={barData["call"]} />
                                        </div>
                                    </> : ""

                                }

                            </div>

                            <div className='heading_bar_chart'>
                                {
                                    barData
                                        ?
                                        <>
                                            <h1>Put Data</h1>
                                            <div className='call-bar'>
                                                <Bar parentName={"Put"} optionData={barData["put"]} />
                                            </div>
                                        </>
                                        :
                                        ""
                                }

                            </div>

                        </div>
                        <div className='line-chart'>
                            {
                                value && arr ?
                                    <Line label={labelHolder} mapData={mapData} symbolName={selectedSymbol} min={minValue} max={maxValue} arr={arr} /> : ""
                            }
                        </div>
                    </div>
                    : <div className='graph-container'>
                        <div className='bar-chart'>

                            <div className='heading_bar_chart'>
                                <div className="card">
                                    <div className="border-round border-1 surface-border p-4 surface-card">
                                        <div className="flex mb-3">
                                            <Skeleton shape="circle" size="4rem" className="mr-2" style={{ margin: '0', padding: '0' }} />
                                            <div>
                                                <Skeleton width="10rem" className="mb-2" style={{ margin: '10px', padding: '10px' }} />
                                                <Skeleton width="5rem" className="mb-2" style={{ margin: '10px', padding: '10px' }} />
                                                <Skeleton height=".5rem" style={{ margin: '10px', padding: '10px' }} />
                                            </div>
                                        </div>
                                        <Skeleton width="100%" height="150px" style={{ margin: '10px', padding: '10px' }} />
                                        <div className="flex justify-content-between mt-3">
                                            <Skeleton width="4rem" height="2rem" style={{ margin: '10px', padding: '10px' }} />
                                            <Skeleton width="4rem" height="2rem" style={{ margin: '10px', padding: '10px' }} />
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className='heading_bar_chart'>

                                <div className="card">
                                    <div className="border-round border-1 surface-border p-4 surface-card">
                                        <div className="flex mb-3">
                                            <Skeleton shape="circle" size="4rem" className="mr-2" style={{ margin: '0', padding: '0' }} />
                                            <div>
                                                <Skeleton width="10rem" className="mb-2" style={{ margin: '10px', padding: '10px' }} />
                                                <Skeleton width="5rem" className="mb-2" style={{ margin: '10px', padding: '10px' }} />
                                                <Skeleton height=".5rem" style={{ margin: '10px', padding: '10px' }} />
                                            </div>
                                        </div>
                                        <Skeleton width="100%" height="150px" style={{ margin: '10px', padding: '10px' }} />
                                        <div className="flex justify-content-between mt-3">
                                            <Skeleton width="4rem" height="2rem" style={{ margin: '10px', padding: '10px' }} />
                                            <Skeleton width="4rem" height="2rem" style={{ margin: '10px', padding: '10px' }} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='line-chart'>
                            <div className='skalatonHolder'>
                                <div className="card">
                                    <DataTable value={items} className="p-datatable-striped">
                                        <Column field="code" style={{ width: '25%' }} body={<Skeleton />}></Column>
                                        <Column field="category" style={{ width: '25%' }} body={<Skeleton />}></Column>
                                        <Column field="quantity" style={{ width: '25%' }} body={<Skeleton />}></Column>
                                    </DataTable>
                                    <DataTable value={items} className="p-datatable-striped">
                                        <Column field="code" style={{ width: '25%' }} body={<Skeleton />}></Column>
                                        <Column field="category" style={{ width: '25%' }} body={<Skeleton />}></Column>
                                        <Column field="quantity" style={{ width: '25%' }} body={<Skeleton />}></Column>
                                    </DataTable>
                                </div>

                            </div>

                        </div>
                    </div>
            }
        </div>
    )
}

export default Graph

{/* <div className='line-chart-one'>
{/* <Slider /> }
</div> */}





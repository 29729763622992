import React, { useState, useEffect, useMemo } from "react";
import "./Line.css";
import { MultiSelect } from "primereact/multiselect";
import { Chart } from "primereact/chart";
import { RadioButton } from "primereact/radiobutton";

import MultiRangeSlider from "multi-range-slider-react";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


function Line({ label, mapData, min, max, arr, symbolName }) {
  // const [selectedPpl, setSelectedPpl] = useState([]);

  // console.log(Array.from(mapData.keys()));
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});



  const [data1, setData1] = useState([]);
  const [value, setValue] = React.useState('Both');

  const [ppls, setPPLS] = useState(["start", "0", "0.25", "0.50", "0.75", "1", "2", "5", "10", "20"]);

  const [selectedPPls, setSelectedPPls] = useState([]);

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const [minCaption, setMinCaption] = useState('');
  const [maxCaption, setMaxCaption] = useState('');


  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    setMinValue(0);
    setMaxValue(0);
  }, [symbolName]);


  useEffect(() => {
    setMinCaption(ppls[minValue]);
    setMaxCaption(ppls[maxValue]);
  }, [minValue, maxValue, ppls]);

  // useEffect(() => {
  //   setMinCaption(ppls[minValue]);
  //   setMaxCaption(ppls[maxValue]);
  //   let newFinalArr = ppls.slice(minValue + 1, maxValue + 1);
  //   if (value == "Positive") {
  //     finalArrForLine = newFinalArr.map(element => "ppl+" + element.replace(".", ""));
  //   } else if (value == "Negative") {
  //     finalArrForLine = newFinalArr.map(element => "ppl-" + element.replace(".", ""));
  //   } else {
  //     finalArrForLine = newFinalArr.flatMap(element => element === "0" ? ["ppl-0"] : ["ppl+" + element.replace(".", ""), "ppl-" + element.replace(".", "")]);
  //   }
  //   setSelectedPPls(finalArrForLine);
  // }, [minValue, maxValue, ppls]);


  // useEffect(() => {
  //   if (arr.length > 0) {
  //     for (let currentArrobj of arr) {
  //       let tempCurrentobj = _.cloneDeep(currentArrobj);
  //       let newDataPoints = _.cloneDeep(currentArrobj.data.slice(min, max + 1));
  //       tempCurrentobj.data = newDataPoints;
  //       mapData.set(tempCurrentobj.name, tempCurrentobj);
  //     }
  //     // console.log(Array.from(mapData.keys()));

  //     let newFinal = [];

  //     let finalArrForLine = [];

  //     let newFinalArr = ppls.slice(minValue + 1, maxValue + 1);
  //     if (value == "Positive") {
  //       finalArrForLine = newFinalArr.map(element => "ppl+" + element.replace(".", ""));
  //     } else if (value == "Negative") {
  //       finalArrForLine = newFinalArr.map(element => "ppl-" + element.replace(".", ""));
  //     } else {
  //       finalArrForLine = newFinalArr.flatMap(element => element === "0" ? ["ppl-0"] : ["ppl+" + element.replace(".", ""), "ppl-" + element.replace(".", "")]);
  //     }

  //     finalArrForLine.forEach((element) => {
  //       let obj = mapData.get(element);
  //       newFinal.push(obj);
  //     });
  //     setData1(newFinal);
  //   }
  // }, [min, max]);



  const handleValueChange = () => {
    if (arr) {
      if (value && mapData && ppls.length > 0) {
        // console.log("function  called");
        let finalArrForLine = [];
        let newFinal = [];

        let newFinalArr = ppls.slice(minValue + 1, maxValue + 1);
        if (value === "Positive") {
          finalArrForLine = newFinalArr.map(element => element === "0" ? "ppl-0" : "ppl+" + element.replace(".", ""));
        } else if (value === "Negative") {
          finalArrForLine = newFinalArr.map(element => element === "0" ? "ppl-0" : "ppl-" + element.replace(".", ""));
        } else {
          finalArrForLine = newFinalArr.flatMap(element => element === "0" ? ["ppl-0"] : ["ppl+" + element.replace(".", ""), "ppl-" + element.replace(".", "")]);
        }
        // console.log(finalArrForLine);

        finalArrForLine.forEach((element) => {
          let obj = mapData.get(element);
          newFinal.push(obj);
        });

        setData1(newFinal);
      }
    }
  }

  function handleValueChangeFinal() {
    console.log("value  changed in Final");
  }

  // useMemo(() => handleValueChange(), [minValue, maxValue, value]);

  useMemo(() => handleValueChange(), [minValue, maxValue, value, min, max]);


  // "5", "15", "15", "20", "25", "30", "35", "40", "45", "50", "60", "70", "80", "90", "100"
  const data = {
    labels: [...label],
    datasets: [...data1],
  };

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--bluegray-800");
    const surfaceBorderX = documentStyle.getPropertyValue("--bluegray-700");

    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.37,
      plugins: {
        legend: {
          labels: {
            color: "white",
            font: {
              size: 15,
            },
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {

              var label = context.dataset.label || "";
              if (label) {
                label += " :  ";
              }
              if (context.parsed.y !== null) {
                // console.log(context.label);
                label += context.parsed.y;
              }

              //   console.log("change : = " +label);
              return label;
            },
            title: function (tooltipItems, data) {
              let title = " IV : ";
              if (tooltipItems.length > 0) {
                title += tooltipItems[0].label; // Assuming tooltipItems[0].label contains the default title
              }
              return title;
            }
          },
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          padding: 10,
          titleFont: {
            size: 20,
          },
          bodyFont: {
            size: 18,
          },
          bodySpacing: 2,
          displayColors: false,
          caretPadding: 10,
          cornerRadius: 10,
          mode: "index",
          intersect: false,
          position: "nearest",
          enabled: true,
          boxWidth: 100,
          boxHeight: 100,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "IV Values",
            padding: { top: 15 }, // Padding added
            color: "white", // Changed color
            font: {
              size: 20,
              weight: 500,
            },
          },
          ticks: {
            color: "white",
            font: {
              size: 16,
              weight: 500,
            },
          },
          grid: {
            display: true,
            drawBorder: true,
            color: surfaceBorderX,
          },
        },
        y: {
          title: {
            display: true,
            text: "PPL (Crores )",
            padding: { top: 0, bottom: 15 }, // Padding added
            color: "white", // Changed color
            font: {
              size: 20,
              weight: 500,
            },
          },
          ticks: {
            color: "white",
            font: {
              size: 16,
              weight: 500,
            },
          },
          grid: {
            display: true,
            color: surfaceBorder,
            drawBorder: true,

          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [data1]);

  return (
    <div className="line_graph">
      <div className="handlemultiselector">
        <div className="radioHolder">
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">PPL's</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel value="Positive" control={<Radio />} label="Positive" />
              <FormControlLabel value="Negative" control={<Radio />} label="Negative" />
              <FormControlLabel value="Both" control={<Radio />} label="Both" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="pplsector">
          <h4> PPL </h4>

          <div className="multi-range-slider-container">
            <MultiRangeSlider
              labels={ppls}
              min={0}
              max={ppls.length - 1}
              minValue={minValue}
              maxValue={maxValue}
              step={1}
              minCaption={minCaption}
              maxCaption={maxCaption}
              onInput={(e) => {
                setMinCaption(ppls[e.minValue]);
                setMaxCaption(ppls[e.maxValue]);
                setMinValue(e.minValue);
                setMaxValue(e.maxValue);

                console.log(e.minValue + " ============ " + e.maxValue);
              }}

            />


          </div>
        </div>

      </div>
      <div className="line_graph_sec">
        <div className="card">
          <Chart type="line" data={data} options={chartOptions} />
        </div>
      </div>
    </div>
  );
}

export default Line;


{/* <div className="card flex justify-content-center">
          <MultiSelect
            value={selectedPpl}
            onChange={handleSelectPPL}
            options={ppls}
            optionLabel="name"
            filter
            placeholder="Select PPl's .."
            maxSelectedLabels={0}
            className="w-full md:w-20rem pplwidthhandle"
          />
        </div> */}



// const ppls_positive = [
//   { name: "PPL +0.25%", code: "ppl+025" },
//   { name: "PPL +0.50%", code: "ppl+050" },
//   { name: "PPL +0.75%", code: "ppl+075" },
//   { name: "PPL +1%", code: "ppl+1" },
//   { name: "PPL +2%", code: "ppl+2" },
//   { name: "PPL +5%", code: "ppl+5" },
//   { name: "PPL +10%", code: "ppl+10" },
//   { name: "PPL +20%", code: "ppl+20" }
// ];


// const ppls_negative = [
//   { name: "PPL -0.25%", code: "ppl-025" },
//   { name: "PPL -0.50%", code: "ppl-050" },
//   { name: "PPL -0.75%", code: "ppl-075" },
//   { name: "PPL -1%", code: "ppl-1" },
//   { name: "PPL -2%", code: "ppl-2" },
//   { name: "PPL -5%", code: "ppl-5" },
//   { name: "PPL -10%", code: "ppl-10" },
//   { name: "PPL -20%", code: "ppl-20" },
// ];

// const ppls_both = [
//   // { name: "PPL 0%", code: "ppl-0" },
//   { name: "PPL -0.25%", code: "ppl-025" },
//   { name: "PPL -0.50%", code: "ppl-050" },
//   { name: "PPL -0.75%", code: "ppl-075" },
//   { name: "PPL -1%", code: "ppl-1" },
//   { name: "PPL -2%", code: "ppl-2" },
//   { name: "PPL -5%", code: "ppl-5" },
//   { name: "PPL -10%", code: "ppl-10" },
//   { name: "PPL -20%", code: "ppl-20" },
//   { name: "PPL +0.25%", code: "ppl+025" },
//   { name: "PPL +0.50%", code: "ppl+050" },
//   { name: "PPL +0.75%", code: "ppl+075" },
//   { name: "PPL +1%", code: "ppl+1" },
//   { name: "PPL +2%", code: "ppl+2" },
//   { name: "PPL +5%", code: "ppl+5" },
//   { name: "PPL +10%", code: "ppl+10" },
//   { name: "PPL +20%", code: "ppl+20" }
// ]


// const handleSelectPPL = (e) => {
//   setSelectedPpl(e.value);
//   let newFinal = [];
//   e.value.forEach((element) => {
//     let obj = mapData.get(element.code);
//     newFinal.push(obj);
//   });
//   // console.log(newFinal);
//   setData1(newFinal);
// };

{/* <div className="divOutput">

    <div className='point'>
        <span>
            Start point :  {weekDays[minValue2]}
        </span>
        <span>
            End Point :  {weekDays[maxValue2]}

        </span>
    </div>
</div> */}
import React, { useEffect, useState } from "react";
import "./Header.css";
import img1 from '../Images/graph1.png';
import img2 from '../Images/graph2.png';
import img3 from '../Images/graph3.png';
import iv from '../Images/iv1.png';
import anime from 'animejs/lib/anime.es.js';




function useDateTime() {
    const [dateTime, setDateTime] = useState(new Date());
    useEffect(() => {
        const intervalId = setInterval(() => {
            setDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);
    return dateTime;
}
// ===========================================================================================\

function Header() {
 

    const animateChart2 = () => {
        anime({
            targets: ['.imageholder', '.imageholdersec', '.headPoint'], // Assuming your chart is within an element with the class 'chart-container'
            opacity: [0,0.1, 0.2, 0.3,0.8, 0.9, 1],
            translateX: ['100%', '0%'],
            scale: [1.5, 1],
            easing: 'easeInOutQuad', // You can change easing as per your preference
            duration: 800, // Duration of the animation in milliseconds
            delay: 100 // Delay before the animation starts in milliseconds
        });
    };
    const animateChart1 = () => {
        anime({
            targets: [ '.headPoint'], // Assuming your chart is within an element with the class 'chart-container'
            opacity: [0,0.1, 0.2, 0.3,0.8, 0.9, 1],
            translateX: ['100%', '0%'],
            scale: [1.5, 1],
            easing: 'easeInOutQuad', // You can change easing as per your preference
            duration: 800, // Duration of the animation in milliseconds
            delay: 100 // Delay before the animation starts in milliseconds
        });
    };

    useEffect(() => {
        animateChart2();
        animateChart1();
    }, [])

    const dateTime = useDateTime();
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const date = dateTime.getDate();
    const hr = dateTime.getHours();
    const min = dateTime.getMinutes();
    const sec = dateTime.getSeconds();



    return (

        <div className="header_main">
            <div className="timer">
                <p className="timer_left">
                    <span>{year}</span>-<span>{month > 9 ? month : `0${month}`}</span>-
                    <span>{date > 9 ? date : `0${date} `}</span>
                    &nbsp;
                    <span>{hr > 9 ? hr : `0${hr}`}</span>:
                    <span>{min > 9 ? min : `0${min}`}</span>:
                    <span>{sec > 9 ? sec : `0${sec}`}</span>
                </p>
            </div>
            <div className="heading">
                <div className="headPoint">
                    <h3>
                        MarkeTopper<sup id="mr">®</sup>
                    </h3>
                    <h4>
                        <span className="secondNumber"> Trading Platform </span>
                        <span className="thirdNumber"> IV Viewer </span>
                    </h4>
                </div>

            </div>
            <div className="imageholder" >
                <img src={img3} alt="Graph 1" />

            </div>
            <div className="imageholdersec" >

                <img src={iv} alt="Graph 1" />
            </div>
        </div>


    );
}

export default Header;
